import React, { useEffect, useState } from "react";

export default function FotosEstudio(props) {
  const [preview, setPreview] = useState([]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      props.setSelectedFile(undefined);
      return;
    }
    const newData = [...props.selectedFile];
    newData[props.selectedFile.length] = e.target.files[0];
    props.setSelectedFile(newData);
  };

  useEffect(() => {
    if (!props.selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = props.selectedFile.map((each) =>
      URL.createObjectURL(each)
    );
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [props.selectedFile]);

  return (
    <>
      <div className="flex flex-col my-2">
        <label className="text-gray-500 text-xs font-medium mb-1">
          IMAGENES DEL ESTUDIO (NO OBLIGATORIO)
        </label>
        <div>
          <input
            type="file"
            onChange={onSelectFile}
            id="selectedFile"
            accept="image/*"
            className="hidden"
          />
          <input
            type="button"
            value="Subir imagen"
            className=" p-1 px-3 mt-1 mb-1 hover:bg-gray-300"
            onClick={() => document.getElementById("selectedFile").click()}
          />
          <div className="flex flex-row flex-wrap gap-2">
            {props.selectedFile &&
              preview.map((each, index) => (
                <>
                  <img
                    alt="Imagen estudio"
                    className="w-44 h-44 z-1"
                    src={each}
                  />
                  <svg
                    className=" z-10 cursor-pointer"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    onClick={() =>
                      props.setSelectedFile(
                        props.selectedFile.filter((_, i) => i !== index)
                      )
                    }
                  >
                    <path
                      fill="#FF0000"
                      d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"
                    ></path>
                  </svg>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
