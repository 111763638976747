import axiosClient from "config/axios";
import { getHTTPFileHeaders } from "./getHTTPHeaders";

export const sendFile = (id_registro, selectedFile) => {
  var formData = new FormData();
  selectedFile.forEach((file) => {
    formData.append("files", file);
  });
  axiosClient
    .post("registros/files/" + id_registro, formData, getHTTPFileHeaders())
    .catch((err) => {
      console.log(err);
      if (err.response.data.code === 401) {
        window.location.href = "/auth/login";
      }
    });
};

export default sendFile;
