import React from "react";
import { Switch, Route } from "react-router-dom";

import EstadoJugador from "views/validation/EstadoJugador.js";

export default function Validation() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-24 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"></div>
          <Switch>
            <Route
              path="/validacion/:id_jugador"
              exact
              component={EstadoJugador}
            />
          </Switch>
        </section>
      </main>
    </>
  );
}
