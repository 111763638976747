import { useState, useEffect } from "react";
import axiosClient from "../../config/axios";
import Alert from "components/Alerts/Alert";
import ConfirmButton from "components/Buttons/ConfirmButton";
import setErrorAlert from "utils/setErrorAlert";
import getHTTPHeaders from "utils/getHTTPHeaders";
import setSuccessAlert from "utils/setSuccessAlert";

function EditarUsuario() {
  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [loadingDatos, setLoadingDatos] = useState(true);

  const [actualizando, setActualizando] = useState(false);

  const [usuario, setUsuario] = useState({
    username: "",
    password: "",
    confirm_password: "",
    mail: "",
  });
  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...usuario };
    newDatos[e.target.name] = e.target.value;
    setUsuario(newDatos);
  };

  const actualizarDatos = () => {
    if (usuario.mail === "") {
      setErrorAlert(setAlert, "Debe ingresar un MAIL");
      return true;
    }
    if (usuario.password !== usuario.confirm_password) {
      setErrorAlert(setAlert, "Las contraseñas no coinciden");
      return false;
    }

    setActualizando(true);
    var payload = {
      username: usuario.username,
      password: usuario.password ?? "",
      activo: true,
      role: true,
      mail: usuario.username,
      userMail: usuario.mail,
    };
    axiosClient
      .put("usuarios/edit", payload, getHTTPHeaders())
      .then((response) => {
        if (response.data.code === 200) {
          setSuccessAlert(setAlert, "Usuario modificado exitosamente");
          setActualizando(false);
        } else {
          setErrorAlert(
            setAlert,
            "Ocurrio un error editando el perfil, intente mas tarde"
          );
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setErrorAlert(
          setAlert,
          "Ocurrio un error editando el perfil, intente mas tarde"
        );
        setActualizando(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("usuarios/profile", getHTTPHeaders())
      .then((response) => {
        if (response.status === 200) {
          let user = response.data.usuario;
          setUsuario(user);
          setLoadingDatos(false);
        } else {
          setErrorAlert(
            setAlert,
            "Ocurrio un error cargando el perfil, intente mas tarde"
          );
        }
      })
      .catch((err) => {
        window.location.href = "/auth/login";
      });
  }, []);

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}

      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Modificar Perfil
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés modificar tu perfil de usuario del sistema. La contraseña no
          se modificara a menos que sea editada.
        </p>

        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE DE USUARIO (NO EDITABLE)
            </label>
            <input
              name="username"
              value={usuario.username}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre de Usuario"}
              disabled={true}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="text-gray-500 text-xs font-medium mb-1">
              MAIL
            </label>
            <input
              name="userMail"
              value={usuario.userMail}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Mail del Usuario"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <hr />
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-6">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              CONTRASEÑA
            </label>
            <input
              name="password"
              type="password"
              value={usuario.password}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none border-0"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Contraseña"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              CONFIRMAR CONTRASEÑA
            </label>
            <input
              name="confirm_password"
              type="password"
              value={usuario.confirm_password}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none border-0"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={
                loadingDatos ? "Cargando..." : "Confirmar Contraseña"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <ConfirmButton
          label={"Guardar cambios"}
          actualizarDatos={actualizarDatos}
          setHabilitarEdicion={setHabilitarEdicion}
          habilitarEdicion={habilitarEdicion}
          actualizando={actualizando}
        />
      </div>
    </>
  );
}

export default EditarUsuario;
