import { useState, useEffect } from "react";
import axiosClient from "config/axios";
import Container from "components/Registro/Container";
import Title from "components/Registro/Title";
import TextAreaInput from "components/Registro/TextAreaInput";
import { useHistory, useParams } from "react-router-dom";
import getHTTPHeaders from "utils/getHTTPHeaders";
import LoadingCard from "components/Cards/LoadingCard";
import DetalleCardiologia from "components/Registro/Detalle/DetalleCardiologia";
import DetalleClinicaMedica from "components/Registro/Detalle/DetalleClinicaMedica";
import DetalleNutricion from "components/Registro/Detalle/DetalleNutricion";
import DetallePsicologia from "components/Registro/Detalle/DetallePsicologia";
import DetalleAsistencia from "components/Registro/Detalle/DetalleAsistencia";
import DetalleOftalmologia from "components/Registro/Detalle/DetalleOftalmologia";
import DetalleBioquimica from "components/Registro/Detalle/DetalleBioquimica";
import DetalleOdontologia from "components/Registro/Detalle/DetalleOdontologia";
import setErrorAlert from "utils/setErrorAlert";
import Alert from "components/Alerts/Alert";

const RouteDetalle = (especialidad, detalle) => {
  switch (especialidad) {
    case "CARDIOLOGIA":
      return <DetalleCardiologia detalle={detalle} disabled={true} />;
    case "NUTRICION":
      return <DetalleNutricion detalle={detalle} disabled={true} />;
    case "CLINICA MEDICA":
      return <DetalleClinicaMedica detalle={detalle} disabled={true} />;
    case "PSICOLOGIA":
      return <DetallePsicologia detalle={detalle} disabled={true} />;
    case "ASISTENCIA SOCIAL":
      return <DetalleAsistencia detalle={detalle} disabled={true} />;
    case "OFTALMOLOGIA":
      return <DetalleOftalmologia detalle={detalle} disabled={true} />;
    case "ODONTOLOGIA":
      return <DetalleOdontologia detalle={detalle} disabled={true} />;
    case "BIOQUIMICA":
      return <DetalleBioquimica detalle={detalle} disabled={true} />;
    default:
      return null;
  }
};

export default function VerEstudio() {
  const [loadingDatos, setLoadingDatos] = useState(true);
  const { id_registro } = useParams();
  const [actualizando, setActualizando] = useState(false);
  const history = useHistory();

  const [data, setData] = useState({
    aprobado: "",
    observaciones: "",
    fecha: "",
  });

  const [detalle, setDetalle] = useState({});

  const [alert, setAlert] = useState({
    show: false,
  });

  const actualizarDatos = () => {
    setActualizando(true);
    axiosClient
      .post("/registros/update/" + id_registro, {}, getHTTPHeaders())
      .then((response) => {
        setActualizando(false);
        history.push("/admin/list-registros");
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setErrorAlert(setAlert, "Hubo un error actualizando el estudio.");
        setActualizando(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("registros/" + id_registro, getHTTPHeaders())
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.registro);
          setDetalle(JSON.parse(response.data.registro.detalle));
          setLoadingDatos(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        console.log(err);
      });
  }, [id_registro]);

  if (loadingDatos) {
    return <LoadingCard />;
  }
  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <Container>
        <div className="flex flex-row justify-between">
          <Title
            title={
              data.especialista.especialidad +
              " a  " +
              data.jugador.apellido +
              " " +
              data.jugador.nombre
            }
            description={data.fecha.replace("T", " ")}
          />
          {data.aprobado === "True" ? (
            data.fecha_editado ? (
              "Actualizado: " + data.fecha_editado.replace("T", " ")
            ) : null
          ) : localStorage.getItem("role") === "CLINICA MEDICA" ||
            localStorage.getItem("role") === "True" ? (
            <button
              onClick={actualizarDatos}
              disabled={actualizando}
              className="bg-green-500 text-white px-4 mb-2 rounded font-semibold flex flex-row items-center justify-center gap-2 hover:bg-green-600 transition duration-300  text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              ACTUALIZAR ESTUDIO A APTO
            </button>
          ) : null}
        </div>
        <hr />
        {RouteDetalle(data.especialista.especialidad, detalle)}
        <hr />
        <TextAreaInput
          title="OBSERVACIONES GENERALES"
          value={data.observaciones}
          disabled={true}
          name="observaciones"
          placeholder="Ingrese observaciones generales a considerar"
          rows={2}
        />
        <hr />
        <div className="flex flex-row">
          {data.imagenes &&
            data.imagenes.map((each) => (
              <>
                <img
                  alt="Imagen estudio"
                  className="w-44 h-44 z-1 m-2"
                  src={"data:image/png;base64, " + each.file}
                />
              </>
            ))}
        </div>
        <hr />
        <span
          className={`bg-${
            data.aprobado === "True" ? "green-500" : "red-500"
          } text-white rounded  py-2 text-xl font-medium mt-4 text-center `}
        >
          {data.aprobado === "True"
            ? "APTO"
            : data.especialista.especialidad !== "CLINICA MEDICA"
            ? "REEVALUAR"
            : "NO APTO"}
        </span>
      </Container>
    </>
  );
}
