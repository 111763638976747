import TextAreaInput from "components/Registro/TextAreaInput";

export default function DetalleOdontologia(props) {
  return (
    <div>
      <TextAreaInput
        title="LESIONES MUCOSAS"
        value={props.detalle.mucosas ?? ""}
        name="mucosas"
        placeholder="Ingrese observaciones de los lesiones mucosas"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="LESIONES ARTICULARES"
        value={props.detalle.articulares ?? ""}
        name="articulares"
        placeholder="Ingrese observaciones de los lesiones articulares"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="LESIONES GINGIVALES"
        value={props.detalle.gingivales ?? ""}
        name="gingivales"
        placeholder="Ingrese observaciones de los lesiones gingivales"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
    </div>
  );
}
