import TextAreaInput from "components/Registro/TextAreaInput";

export default function DetalleClinicaMedica(props) {
  return (
    <div>
      <TextAreaInput
        title="PESO"
        value={props.detalle.peso ?? ""}
        name="peso"
        placeholder="Ingrese observaciones del peso"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="TALLA"
        value={props.detalle.talla ?? ""}
        name="talla"
        placeholder="Ingrese observaciones de la talla"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="ALERGIAS, ANTECEDENTES PATOLOGICOS y FAMILIARES"
        value={props.detalle.patologicos ?? ""}
        name="patologicos"
        placeholder="Ingrese observaciones de antecedentes patologicos"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="POSTURAS Y APOYOS"
        value={props.detalle.posturas ?? ""}
        name="posturas"
        placeholder="Ingrese observaciones de posturas y apoyos"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="LIBRETA SANITARIA"
        value={props.detalle.libreta ?? ""}
        name="libreta"
        placeholder="Ingrese observaciones de libreta sanitaria"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="ULTIMA EXTRACCION DE SANGRE O CONTROL"
        value={props.detalle.ultima ?? ""}
        name="ultima"
        placeholder="Ingrese observaciones de ultima extracción o control"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
    </div>
  );
}
