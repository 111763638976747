import TextAreaInput from "components/Registro/TextAreaInput";

export default function DetalleAsistencia(props) {
  return (
    <div>
      <TextAreaInput
        title="DATOS PERSONALES Y COLEGIO"
        value={props.detalle.personales ?? ""}
        name="personales"
        placeholder="Ingrese observaciones de los datos personalles y colegio"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="FAMILIA Y ENTORNO"
        value={props.detalle.familia ?? ""}
        name="familia"
        placeholder="Ingrese observaciones de la familia"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="VIVIENDA Y SERVICIOS"
        value={props.detalle.vivienda ?? ""}
        name="vivienda"
        placeholder="Ingrese observaciones de la vivienda y servicios"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="SEXUALIDAD"
        value={props.detalle.sexualidad ?? ""}
        name="sexualidad"
        placeholder="Ingrese observaciones de la sexualidad"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="CONSUMO DE DROGAS Y ALCOHOL"
        value={props.detalle.drogas ?? ""}
        name="drogas"
        placeholder="Ingrese observaciones del consumo de drogas"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="TIEMPO LIBRE"
        value={props.detalle.libre ?? ""}
        name="libre"
        placeholder="Ingrese observaciones del tiempo libre"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
    </div>
  );
}
