import React, { useReducer } from "react";
import adminContext from "./adminContext";
import adminReducer from "./adminReducer";

import { MODAL_BAJA_CATE, SET_FLAG_JWT } from "../../types/index.js";

const AdminState = (props) => {
  const initialState = {
    modalBajaCate: false,
    modalBajaCurso: false,
    modalBajaProfe: false,

    JWT_Flag: null,
  };

  const [state, dispatch] = useReducer(adminReducer, initialState);

  const setModalBajaCate = (modalState) => {
    dispatch({
      type: MODAL_BAJA_CATE,
      payload: modalState,
    });
  };

  const setJWTFlag = (flagState) => {
    dispatch({
      type: SET_FLAG_JWT,
      payload: flagState,
    });
  };

  const checkJWT = (flagState) => {
    // aca deberia pegarle al endpoint de Self (cuando esté)
    if (localStorage.getItem("token")) {
      setJWTFlag(flagState);
    } else {
      window.location.href = "/";
    }
  };

  const log_out = () => {
    localStorage.clear();
    // window.location.href = "/";
  };

  return (
    <adminContext.Provider
      value={{
        modalBajaCate: state.modalBajaCate,
        modalBajaCurso: state.modalBajaCurso,
        modalBajaProfe: state.modalBajaProfe,
        JWT_Flag: state.JWT_Flag,
        setModalBajaCate,
        log_out,
        checkJWT,
        setJWTFlag,
      }}
    >
      {props.children}
    </adminContext.Provider>
  );
};

export default AdminState;
