import React from "react";

export default function LoadingData() {
  return (
    <>
      <div className="rounded-lg bg-blue-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
        <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
          <path
            d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
            opacity=".5"
            fill="currentColor"
          ></path>
          <path
            d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
            fill="currentColor"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 12 12"
              to="360 12 12"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
          </path>
        </svg>
      </div>
    </>
  );
}
