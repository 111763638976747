import React, { useContext, useEffect, useState } from "react";
import axiosClient from "config/axios";
import { Link } from "react-router-dom";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";
// Table components
import NoData from "components/Table/NoData";
import LoadingData from "components/Table/LoadingData";
import Pagination from "components/Table/Pagination";
import getHTTPHeaders from "utils/getHTTPHeaders";
import SelectJugador from "components/Registro/SelectJugador";
import SelectEspecialista from "components/Registro/SelectEspecialista";
import { getToday } from "utils/date";
import setErrorAlert from "utils/setErrorAlert";
import setSuccessAlert from "utils/setSuccessAlert";

function TableRegistro() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;
  const { modalBajaCate, setModalBajaCate } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datosModal, setDatosModal] = useState(null);
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [selectedJugador, setSelectedJugador] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("0");
  const [alertData, setAlertData] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const [fechaInicio, setFechaInicio] = useState(getToday(7));
  const [fechaFin, setFechaFin] = useState(getToday(0));
  const [selectedEspecialista, setSelectedEspecialista] = useState("0");
  // Pagination state
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  function bajaHandler(id_especialista) {
    setLoadingBaja(true);
    axiosClient
      .delete("registros/" + id_especialista, getHTTPHeaders())
      .then((response) => {
        if (response.status === 200) {
          let especialistas_sin_deleted = datos.filter(
            (p) => p.id !== id_especialista
          );
          setSuccessAlert(
            setAlertData,
            "Se dió de baja el estudio correctamente"
          );
          setModalBajaCate(false);
          setDatos(especialistas_sin_deleted);
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
        } else {
          setModalBajaCate(false);
          console.log("error");
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
          setErrorAlert(
            setAlertData,
            "Hubo un error dando de baja el estudio, intentá más tarde"
          );
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setErrorAlert(
          setAlertData,
          "Hubo un error dando de baja el estudio, intentá más tarde"
        );
        setModalBajaCate(false);
        console.log(err);
        setLoadingBaja(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(
        "registros?inactivos=True&jugador_id=" +
          selectedJugador +
          "&especialista_id=" +
          selectedEspecialista +
          "&fecha_inicio=" +
          fechaInicio +
          "&fecha_fin=" +
          fechaFin +
          "&size=" +
          itemsPerPage +
          "&page=" +
          pageCount +
          "&estado=" +
          selectedStatus,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.registros.items);
          setTotalItems(response.data.registros.total);
          setTotalPages(
            Math.ceil(response.data.registros.total / itemsPerPage)
          );
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, [
    updateFlag,
    selectedJugador,
    pageCount,
    selectedEspecialista,
    fechaFin,
    fechaInicio,
    selectedStatus,
  ]);

  useEffect(() => {
    if (datosModal) {
      setModalBajaCate(true);
    }
  }, [datosModal]);

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const darDeBajaCate = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  return (
    <>
      {alertData.show ? (
        <Alert
          bgColor={alertData.bgColor}
          message={alertData.message}
          funcionCierre={() => {
            setAlertData({ ...alertData, show: false });
          }}
        />
      ) : null}

      <div className="rounded-lg shadow-lg text-left mt-2 border-2 bg-white border-gray-900 pt-6 ">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between p-4 pt-0 pb-0 items-center">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">Estudios</h2>
          </div>
          <div className="flex flex-row gap-2 h-18 ">
            <div className="flex flex-col my-3">
              <label className="text-gray-500 text-xs font-medium mb-1">
                RESULTADO
              </label>
              <select
                name="estado"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className=" rounded pr-8  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none "
              >
                <option value="0">Todos</option>
                <option value="APTO">APTO</option>
                <option value="REEVALUAR">REEVALUAR</option>
              </select>
            </div>
            <SelectJugador
              label="Todos"
              selectedJugador={selectedJugador}
              setSelectedJugador={setSelectedJugador}
            />
            {localStorage.getItem("role") === "True" ||
            localStorage.getItem("role") === "CLINICA MEDICA" ? (
              <SelectEspecialista
                selectedEspecialista={selectedEspecialista}
                setSelectedEspecialista={setSelectedEspecialista}
              />
            ) : null}
            <div className="flex flex-col my-3">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                FECHA INICIO
              </label>
              <input
                name="fecha_inicio"
                type="date"
                value={fechaInicio}
                className="rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Fecha inicio de busqueda"}
                onChange={(e) => setFechaInicio(e.target.value)}
              />
            </div>
            <div className="flex flex-col my-3">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                FECHA FIN
              </label>
              <input
                name="fecha_fin"
                type="date"
                value={fechaFin}
                className="rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Fecha fin de busqueda"}
                onChange={(e) => setFechaFin(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className=" p-4 py-6">
          {!loading && datos.length > 0 ? (
            <>
              {datos.map((p) => (
                <div
                  className="border rounded p-2 flex flex-col justify-between lg:grid lg:grid-cols-5 lg:gap-2"
                  key={p.id}
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">JUGADOR</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.jugador.apellido +
                        " " +
                        p.jugador.nombre +
                        " - " +
                        p.jugador.dni}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      ESPECIALISTA
                    </p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.especialista.apellido +
                        " " +
                        p.especialista.nombre +
                        " - " +
                        p.especialista.especialidad}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">FECHA</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.fecha.replace("T", " ")}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">RESULTADO</p>
                    <span
                      className={`bg-${
                        p.aprobado === "True" ? "green-500" : "red-500"
                      } text-white rounded px-2 py-0.5 text-sm font-medium `}
                    >
                      {p.aprobado === "True"
                        ? "APTO"
                        : p.especialista.especialidad !== "CLINICA MEDICA"
                        ? "REEVALUAR"
                        : "NO APTO"}
                    </span>
                  </div>
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                    <div className="lg:grid lg:grid-cols-2 flex flex-col gap-2 text-sm lg:text-md mt-1">
                      <Link
                        className="bg-indigo-500 text-white px-2 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 w-full text-sm"
                        to={`/admin/ver-registro/${p.id}`}
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                          <path
                            d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        VER DETALLE
                      </Link>
                      <button
                        onClick={() => setDatosModal(p)}
                        className="bg-red-500 text-white px-2 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 w-full text-sm"
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                          <path
                            d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        BAJA
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <Pagination
                pageCount={pageCount}
                setPageCount={setPageCount}
                setTotalPages={setTotalPages}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                totalPages={totalPages}
              />
            </>
          ) : loading ? (
            <LoadingData />
          ) : !datos.length > 0 ? (
            <NoData text={"No se encontraron resultados para la busqueda"} />
          ) : null}
        </div>
        {datosModal && modalBajaCate ? (
          <ModalBaja
            titulo={"Baja de Estudio"}
            descripcion={`¿ Desea dar de baja el estudio ?`}
            funcionCierre={cerrarModal}
            funcionAccion={darDeBajaCate}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableRegistro;
