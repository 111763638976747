export const setErrorAlert = (setAlert, message) => {
  // alert state goes on global context
  setAlert({
    ...alert,
    bgColor: "red-500",
    message: message,
    show: true,
  });
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default setErrorAlert;
