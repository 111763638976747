import { useState, useEffect, useContext } from "react";
import axiosClient from "../../config/axios";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import AdminContext from "context/admin/adminContext";
import Alert from "components/Alerts/Alert";
import ConfirmButton from "components/Buttons/ConfirmButton";
import setErrorAlert from "utils/setErrorAlert";
import { getHTTPFileHeaders } from "utils/getHTTPHeaders";
import getHTTPHeaders from "utils/getHTTPHeaders";
import setSuccessAlert from "utils/setSuccessAlert";
import { QRCodeCanvas } from "qrcode.react";

const frontend_url = process.env.REACT_APP_FRONTEND_URL;

function EditarJugador() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);
  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [estado, setEstado] = useState(true);
  const [loadingDatos, setLoadingDatos] = useState(true);
  const [actualizando, setActualizando] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [preview, setPreview] = useState([]);
  const [jugadorDatos, setJugadorDatos] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    fecha_nacimiento: "",
    nombre_responsable: "",
    obra_social: "0",
    telefono_responsable: "",
    filename: "",
    activo: true,
  });

  const updateSelect = (value, target) => {
    var newDatos = { ...jugadorDatos };
    newDatos[target] = value;
    setJugadorDatos(newDatos);
  };

  const { id_jugador } = useParams();

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...jugadorDatos };
    newDatos[e.target.name] = e.target.value;
    setJugadorDatos(newDatos);
  };

  const onSwitchChange = () => {
    var newDatos = { ...jugadorDatos };
    setEstado(!estado);
    newDatos["activo"] = !estado;
    setJugadorDatos(newDatos);
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile([]);
      return;
    }
    setSelectedFile([e.target.files[0]]);
  };

  const sendFile = (id_jugador) => {
    var formData = new FormData();
    formData.append("file", selectedFile[0]);
    axiosClient
      .post("jugadores/foto/" + id_jugador, formData, getHTTPFileHeaders())
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  };

  const downloadQR = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr_" + jugadorDatos.dni + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    if (!selectedFile.length > 0) {
      setPreview([]);
      return;
    }
    const objectUrl = selectedFile.map((each) => URL.createObjectURL(each));
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const actualizarDatos = () => {
    if (jugadorDatos.nombre === "") {
      setErrorAlert(setAlert, "Debe ingresar un NOMBRE");
      return true;
    }

    if (jugadorDatos.apellido === "") {
      setErrorAlert(setAlert, "Debe ingresar un APELLIDO");
      return true;
    }

    if (jugadorDatos.disciplina === "0") {
      setErrorAlert(setAlert, "Debe ingresar una DISCIPLINA");
      return true;
    }

    if (jugadorDatos.club === "0") {
      setErrorAlert(setAlert, "Debe ingresar un CLUB");
      return true;
    }

    if (jugadorDatos.obra_social === "0") {
      setErrorAlert(setAlert, "Debe ingresar un OBRA SOCIAL");
      return true;
    }

    if (jugadorDatos.dni === "") {
      setErrorAlert(setAlert, "Debe ingresar un DNI");
      return true;
    }

    if (jugadorDatos.fecha_nacimiento === "") {
      setErrorAlert(setAlert, "Debe ingresar una FECHA DE NACIMIENTO");
      return true;
    }
    if (jugadorDatos.fecha_vencimiento_dni === "") {
      setErrorAlert(setAlert, "Debe ingresar una FECHA DE VENCIMIENTO DE DNI");
      return true;
    }
    if (jugadorDatos.posicion === "") {
      setErrorAlert(setAlert, "Debe ingresar una POSICION");
      return true;
    }
    if (jugadorDatos.nro_pasaporte === "") {
      setErrorAlert(setAlert, "Debe ingresar un NRO DE PASAPORTE");
      return true;
    }
    if (jugadorDatos.nro_tramite_dni === "") {
      setErrorAlert(setAlert, "Debe ingresar un NRO DE TRAMITE DE DNI");
      return true;
    }
    setActualizando(true);
    var payload = {
      nombre: jugadorDatos.nombre,
      apellido: jugadorDatos.apellido,
      club: jugadorDatos.club,
      disciplina: jugadorDatos.disciplina,
      obra_social: jugadorDatos.obra_social,
      dni: jugadorDatos.dni,
      fecha_nacimiento: jugadorDatos.fecha_nacimiento,
      nombre_responsable: jugadorDatos.nombre_responsable,
      telefono_responsable: jugadorDatos.telefono_responsable,
      activo: jugadorDatos.activo,
      nro_pasaporte: jugadorDatos.nro_pasaporte,
      nro_tramite_dni: jugadorDatos.nro_tramite_dni,
      fecha_vencimiento_dni: jugadorDatos.fecha_vencimiento_dni,
      posicion: jugadorDatos.posicion,
    };
    axiosClient
      .put("jugadores/" + id_jugador, payload, getHTTPHeaders())
      .then((response) => {
        if (response.data.code === 200) {
          setSuccessAlert(setAlert, "Jugador modificado exitosamente!");
        } else {
          setErrorAlert(
            setAlert,
            "Hubo un error editando el jugador, intentá más tarde"
          );
        }
        setActualizando(false);
        // Crea la firma digital del jugador
        if (selectedFile.length > 0) {
          sendFile(id_jugador);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setErrorAlert(
          setAlert,
          "Hubo un error editando el jugador, intentá más tarde"
        );

        setActualizando(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("jugadores/" + id_jugador, getHTTPHeaders())
      .then((response) => {
        if (response.status === 200) {
          setJugadorDatos(response.data.jugador);
          setEstado(response.data.jugador.activo === "True" ? true : false);
          setLoadingDatos(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        console.log(err);
      });
  }, [id_jugador]);

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-2">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Editar Jugador
        </h1>
        <div className="lg:grid lg:grid-cols-4 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE
            </label>
            <input
              name="nombre"
              value={jugadorDatos.nombre}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              APELLIDO
            </label>
            <input
              name="apellido"
              value={jugadorDatos.apellido}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Apellido"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              OBRA SOCIAL
            </label>
            <select
              name="obra_social"
              value={jugadorDatos.obra_social}
              onChange={(e) => updateSelect(e.target.value, "obra_social")}
              className=" rounded px-2  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none  "
            >
              <option value="0">Seleccione obra social</option>
              <option value="IOMA">IOMA</option>
              <option value="OSPE">OSPE</option>
              <option value="OSDE">OSDE</option>
              <option value="LOSFA">LOSFA</option>
              <option value="OSPECOM">OSPECOM</option>
              <option value="PERSONAL DE FARMACIA">PERSONAL DE FARMACIA</option>
              <option value="OSECAC">OSECAC</option>
              <option value="PRIMEDIC">PRIMEDIC</option>
              <option value="OSAP">OSAP</option>
              <option value="DOSUBA">DOSUBA</option>
              <option value="OSPECON">OSPECON</option>
              <option value="SANCOR">SANCOR</option>
              <option value="OTROS">OTROS</option>
              <option value="NO TIENE">NO TIENE</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ACTIVO
            </label>
            <Switch
              name="activo"
              onChange={onSwitchChange}
              checked={jugadorDatos.activo}
            />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-4 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FECHA DE NACIMIENTO
            </label>
            <input
              name="fecha_nacimiento"
              type="date"
              value={jugadorDatos.fecha_nacimiento}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Fecha de nacimiento"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              CLUB
            </label>
            <select
              name="club"
              value={jugadorDatos.club}
              onChange={(e) => updateSelect(e.target.value, "club")}
              className=" rounded px-2  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none  "
            >
              <option value="0">Seleccione un club</option>
              <option value="GIMNASIA Y ESGRIMA LA PLATA">
                GIMNASIA Y ESGRIMA LA PLATA
              </option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              DISCIPLINA
            </label>
            <select
              name="disciplina"
              value={jugadorDatos.disciplina}
              onChange={(e) => updateSelect(e.target.value, "disciplina")}
              className=" rounded px-2  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none  "
            >
              <option value="0">Seleccione una disciplina</option>
              <option value="FUTBOL">FUTBOL</option>
              <option value="VOLEY">VOLEY</option>
              <option value="BASQUET">BASQUET</option>
              <option value="HOCKEY">HOCKEY</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              POSICION
            </label>
            <select
              name="posicion"
              value={jugadorDatos.posicion}
              onChange={(e) => updateSelect(e.target.value, "posicion")}
              className=" rounded px-2  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none  "
            >
              <option value="0">Seleccione una posicion</option>
              <option value="ARQUERO">ARQUERO</option>
              <option value="DEFENSOR">DEFENSOR</option>
              <option value="CENTRAL DERECHO">CENTRAL DERECHO</option>
              <option value="CENTRAL IZQUIERDO">CENTRAL IZQUIERDO</option>
              <option value="LATERAL DERECHO">LATERAL DERECHO</option>
              <option value="LATERAL IZQUIERDA">LATERAL IZQUIERDA</option>
              <option value="VOLANTE CENTRAL">VOLANTE CENTRAL</option>
              <option value="VOLANTE POR DERECHA">VOLANTE POR DERECHA</option>
              <option value="ATACANTE POR IZQUIERDA">
                ATACANTE POR IZQUIERDA
              </option>
              <option value="ATACANTE CENTRAL">ATACANTE CENTRAL</option>
              <option value="ATACANTE POR DERECHA">ATACANTE POR DERECHA</option>
              <option value="ATACANTE POR IZQUIERDA">
                ATACANTE POR IZQUIERDA
              </option>
            </select>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-4 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              DNI
            </label>
            <input
              name="dni"
              value={jugadorDatos.dni}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "DNI"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NRO TRAMITE DNI
            </label>
            <input
              name="nro_tramite_dni"
              value={jugadorDatos.nro_tramite_dni}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "NRO tramite DNI"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FECHA VENCIMIENTO DNI
            </label>
            <input
              name="fecha_vencimiento_dni"
              type="date"
              value={jugadorDatos.fecha_vencimiento_dni}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Fecha de nacimiento"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NRO PASAPORTE
            </label>
            <input
              name="nro_pasaporte"
              value={jugadorDatos.nro_pasaporte}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "NRO pasaporte"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <hr />
        <div className="lg:grid lg:grid-cols-4 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              LINK Y CODIGO QR AUTOGENERADO PARA VALIDACION DE ALTA MEDICA
            </label>
            <input
              name="link_validacion"
              value={frontend_url + "validacion/" + jugadorDatos.dni}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={
                loadingDatos
                  ? "Cargando..."
                  : "Link para validacion de alta medica"
              }
              disabled={true}
            />
          </div>
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              width: "auto",
              cursor: "pointer",
            }}
            onClick={downloadQR}
          >
            <QRCodeCanvas
              id="qrcode"
              value={frontend_url + "validacion/" + jugadorDatos.dni}
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 64,
                maxHeight: 64,
                width: "100%",
              }}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE Y APELLIDO RESPONSABLE DEL JUGADOR
            </label>
            <input
              name="nombre_responsable"
              value={jugadorDatos.nombre_responsable}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={
                loadingDatos
                  ? "Cargando..."
                  : "Nombre y apellido del responsable"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              TELEFONO RESPONSABLE DEL JUGADOR
            </label>
            <input
              name="telefono_responsable"
              value={jugadorDatos.telefono_responsable}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={
                loadingDatos ? "Cargando..." : "Telefono del responsable"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <hr />

        <div className="lg:grid lg:grid-cols-1 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FOTO CARNET DEL JUGADOR
            </label>
            <div>
              <input
                type="file"
                onChange={onSelectFile}
                id="selectedFile"
                accept="image/*"
                className="hidden"
              />
              <input
                type="button"
                value="Editar foto"
                className=" p-1 px-3 mt-1 mb-1 hover:bg-gray-300"
                onClick={() => document.getElementById("selectedFile").click()}
              />
              {selectedFile &&
                preview.map((each, index) => (
                  <>
                    <img
                      alt="Foto de jugador"
                      className="w-44 h-44 z-1 -mr-4"
                      src={each}
                    />
                  </>
                ))}
              {preview.length < 1 && jugadorDatos.filename !== "" ? (
                <div className="flex flex-row flex-wrap gap-2">
                  <>
                    <img
                      alt="Foto de jugador"
                      className="w-44 h-44 z-1 -mr-4"
                      src={"data:image/png;base64," + jugadorDatos.filename}
                    />
                  </>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <ConfirmButton
          label={"Guardar cambios"}
          actualizarDatos={actualizarDatos}
          setHabilitarEdicion={setHabilitarEdicion}
          habilitarEdicion={habilitarEdicion}
          actualizando={actualizando}
        />
      </div>
    </>
  );
}

export default EditarJugador;
