import React from "react";

export default function ConfirmButton(props) {
  return (
    <div className="flex justify-end mt-6">
      <button
        onClick={() => {
          if (props.habilitarEdicion) {
            props.actualizarDatos();
          } else {
            props.setHabilitarEdicion(!props.habilitarEdicion);
          }
        }}
        className={
          props.habilitarEdicion
            ? props.actualizando
              ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
              : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
            : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
        }
        disabled={props.actualizando}
      >
        {props.habilitarEdicion ? (
          props.actualizando ? (
            <svg
              className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
              <path
                d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                fill="currentColor"
              ></path>
            </svg>
          )
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-1 mb-0.5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        )}
        <p className="mb-0.5">
          {" "}
          {props.habilitarEdicion
            ? props.actualizando
              ? "Actualizando"
              : props.label
            : "Editar"}
        </p>
      </button>
    </div>
  );
}
