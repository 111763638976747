import { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";

const frontend_url = process.env.REACT_APP_FRONTEND_URL;

export default function QRJugador() {
  const [dni, setDNI] = useState("");

  const downloadQR = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr_dni_deportivo_" + dni + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <section className="relative w-full h-full py-40 min-h-screen">
      <div className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0  w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 justify-center p-4">
                <>
                  <div className="text-blueGray-600 text-center my-4 font-bold">
                    <p className="uppercase">
                      INGRESE DNI PARA OBTENER SU ACCESO QR
                    </p>
                  </div>
                  <div className="relative w-full mb-5">
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Ingrese su DNI"
                      value={dni}
                      onChange={(e) => setDNI(e.target.value.substring(0, 9))}
                    />
                  </div>
                  {dni !== "" ? (
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        width: "auto",
                        cursor: "pointer",
                      }}
                      className="text-blueGray-600 text-center "
                      onClick={downloadQR}
                    >
                      <QRCodeCanvas
                        id="qrcode"
                        value={frontend_url + "validacion/" + dni}
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 128,
                          maxHeight: 128,
                          width: "100%",
                        }}
                      />

                      <p className="uppercase mt-1">CLICK PARA DESCARGAR</p>
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
