import React from "react";

export default function NoData(props) {
  return (
    <>
      <div className="rounded-lg bg-purple-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
        <p className="text-xl font-bold flex flex-row justify-center items-center gap-2">
          <svg className="w-8 h-8 text-purple-800" viewBox="0 0 24 24">
            <path
              d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
              fill="currentColor"
            ></path>
          </svg>
          {props.text}
        </p>
      </div>
    </>
  );
}
