import React, { useContext, useEffect, useState } from "react";
import axiosClient from "config/axios";
import { Link } from "react-router-dom";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";
// Table components
import NoData from "components/Table/NoData";
import LoadingData from "components/Table/LoadingData";
import Pagination from "components/Table/Pagination";
import getHTTPHeaders from "utils/getHTTPHeaders";
import setErrorAlert from "utils/setErrorAlert";
import setSuccessAlert from "utils/setSuccessAlert";

function TableEspecialistas() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;
  const { modalBajaCate, setModalBajaCate } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datosModal, setDatosModal] = useState(null);
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [alertData, setAlertData] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const [nameFilter, setNameFilter] = useState("");
  // Pagination state
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  function bajaHandler(id_especialista) {
    setLoadingBaja(true);
    axiosClient
      .delete("especialistas/" + id_especialista, getHTTPHeaders())
      .then((response) => {
        if (response.status === 200) {
          let especialistas_sin_deleted = datos.filter(
            (p) => p.id !== id_especialista
          );
          setSuccessAlert(
            setAlertData,
            "Se dió de baja el especialista correctamente"
          );
          setModalBajaCate(false);
          setDatos(especialistas_sin_deleted);
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
        } else {
          setModalBajaCate(false);
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
          setErrorAlert(
            setAlertData,
            "Hubo un error dando de baja el especialista, intentá más tarde"
          );
        }
        //setActualizando(false);
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setErrorAlert(
          setAlertData,
          "Hubo un error dando de baja el especialista, intentá más tarde"
        );
        setModalBajaCate(false);
        setLoadingBaja(false);
      });
  }

  //setActualizando(true);
  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(
        "especialistas?inactivos=True&nombre=" +
          nameFilter +
          "&size=" +
          itemsPerPage +
          "&page=" +
          pageCount,
        getHTTPHeaders()
      )
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.especialistas.items);
          setTotalItems(response.data.especialistas.total);
          setTotalPages(
            Math.ceil(response.data.especialistas.total / itemsPerPage)
          );
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, [updateFlag, nameFilter, pageCount]);

  useEffect(() => {
    if (datosModal) {
      setModalBajaCate(true);
    }
  }, [datosModal]);

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const darDeBajaCate = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  return (
    <>
      {alertData.show ? (
        <Alert
          bgColor={alertData.bgColor}
          message={alertData.message}
          funcionCierre={() => {
            setAlertData({ ...alertData, show: false });
          }}
        />
      ) : null}

      <div className="rounded-lg shadow-lg text-left mt-2 border-2 bg-white border-gray-900 pt-6 ">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between p-4 pt-0 pb-0 items-center">
          <div>
            <h2 className="text-2xl font-semibold leading-tight">
              {" "}
              Listado de Especialistas
            </h2>
            <p className=" pt-2 text-gray-500">
              Acá podés crear y gestionar los especialistas. Un especialista
              podra cargar estudios de su especialidad a un jugador .
            </p>
          </div>
          <div className="flex flex-row gap-2 h-18">
            <div className=" bg-gray-100 px-2 font-medium text-lg rounded flex flex-row justify-start lg:justify-center items-center gap-2 border border-gray-700 ">
              <svg className="w-7 h-7" viewBox="0 0 24 24">
                <g fill="currentColor">
                  <path d="M11 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 7.446 2.032l1.761 1.76a1 1 0 0 1-1.414 1.415l-1.761-1.761A4 4 0 0 1 7 11z"></path>
                  <path d="M12 4a8 8 0 1 0 0 16a8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12z"></path>
                </g>
              </svg>
              <input
                placeholder="Buscar por nombre"
                onChange={(e) => setNameFilter(e.target.value)}
                className="bg-gray-100 focus:outline-none focus:ring-0 font-medium "
              />
            </div>
            <Link
              className=" bg-green-500 hover:bg-green-600 transition duration-300 px-4 py-2 text-white font-medium text-lg rounded flex flex-row justify-center items-center gap-2 w-full "
              to="/admin/add-especialista"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m-5-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                  fill="currentColor"
                ></path>
              </svg>
              Nuevo Especialista
            </Link>
          </div>
        </div>
        <div className=" p-4 py-6">
          {!loading && datos.length > 0 ? (
            <>
              {datos.map((p) => (
                <div
                  className="border rounded p-2 flex flex-col justify-between lg:grid lg:grid-cols-5 lg:gap-4"
                  key={p.id}
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      NOMBRE Y APELLIDO
                    </p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.nombre + " " + p.apellido}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">MATRICULA</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.matricula}
                    </p>
                  </div>

                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ESTADO</p>
                    <span
                      className={`bg-${
                        p.usuario.activo === "True" ? "green-500" : "red-500"
                      } text-white rounded px-2 text-sm font-medium mt-1`}
                    >
                      {p.usuario.activo === "True" ? "Activo" : "Oculto"}
                    </span>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      ESPECIALIDAD
                    </p>
                    <span
                      className={`bg-sky-500 text-white rounded px-2 text-sm font-medium mt-1`}
                    >
                      {p.especialidad}
                    </span>
                  </div>
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                    <div className="lg:grid lg:grid-cols-2 flex flex-col gap-2 text-sm lg:text-md mt-1">
                      <Link
                        className="bg-indigo-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 w-full text-sm"
                        to={`/admin/edit-especialista/${p.id}`}
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                          <path
                            d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        EDITAR
                      </Link>
                      <button
                        onClick={() => setDatosModal(p)}
                        className="bg-red-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 w-full text-sm"
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                          <path
                            d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        BAJA
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <Pagination
                pageCount={pageCount}
                setPageCount={setPageCount}
                setTotalPages={setTotalPages}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                totalPages={totalPages}
              />
            </>
          ) : loading ? (
            <LoadingData />
          ) : !datos.length > 0 ? (
            <NoData text={"Aún no hay especialistas dados de alta"} />
          ) : null}
        </div>
        {datosModal && modalBajaCate ? (
          <ModalBaja
            titulo={"Baja de Especialista"}
            descripcion={`¿ Desea dar de baja el especialista ||'${datosModal.nombre}'|| ?`}
            funcionCierre={cerrarModal}
            funcionAccion={darDeBajaCate}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableEspecialistas;
