import React, { useContext } from "react";
import AdminContext from "context/admin/adminContext";
import { createPopper } from "@popperjs/core";
import userLogo from "./user_profile.png";
import { Link } from "react-router-dom";

const UserDropdown = () => {
  const adminContext = useContext(AdminContext);
  const { log_out } = adminContext;
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={userLogo}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "hidden md:block lg:block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          className={
            "text-sm py-2 px-4 hidden md:block lg:block font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-red-400 transition duration-200 hover:text-white"
          }
          onClick={log_out}
          to="/"
        >
          Cerrar Sesión
        </Link>
      </div>
    </>
  );
};

export default UserDropdown;
