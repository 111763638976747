import React from "react";
import { useEffect, useState } from "react";
import axiosClient from "config/axios";

import getHTTPHeaders from "utils/getHTTPHeaders";

export default function SelectEspecialista(props) {
  const [especialistas, setEspecialistas] = useState([]);
  useEffect(() => {
    axiosClient("especialistas?inactivos=True", getHTTPHeaders()).then(
      (res) => {
        setEspecialistas(res.data.especialistas.items);
      }
    );
  }, []);
  return (
    <>
      <div className="flex flex-col my-3">
        <label className="text-gray-500 text-xs font-medium mb-1">
          ESPECIALISTA
        </label>
        <select
          onChange={(e) => props.setSelectedEspecialista(e.target.value)}
          value={props.selectedJugador}
          className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
        >
          <option selected value="0">
            Todos
          </option>
          {especialistas.map((especialista) => (
            <option key={especialista.id} value={especialista.id}>
              {especialista.apellido +
                " " +
                especialista.nombre +
                " (" +
                especialista.especialidad +
                ")"}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
