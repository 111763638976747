import { useState, useEffect } from "react";
import axiosClient from "../../config/axios";
import { useParams } from "react-router-dom";
import defaultImage from "../../assets/img/dni.png";

import { getHTTPHeaders } from "utils/getHTTPHeaders";
import LoadingCard from "components/Cards/LoadingCard";

export default function EstadoJugador() {
  const [loadingDatos, setLoadingDatos] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    aprobado: "",
    jugador: { nombre: "", apellido: "" },
  });

  const { id_jugador } = useParams();
  useEffect(() => {
    axiosClient
      .get("validacion/" + id_jugador, getHTTPHeaders())
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
          setLoadingDatos(false);
        } else {
          setLoadingDatos(false);
        }
      })
      .catch((err) => {
        setLoadingDatos(false);
        setError(true);
      });
  }, [id_jugador]);

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-3/12 px-4">
          {loadingDatos ? (
            <LoadingCard />
          ) : (
            <div className="relative flex flex-col min-w-0  w-full mb-6 shadow-lg rounded-lg bg-white border-0 justify-center">
              {!error ? (
                <>
                  <img
                    alt="Imagen jugador"
                    className=" h-64 p-4 z-1 object-fit"
                    src={
                      data.jugador.filename !== ""
                        ? "data:image/png;base64, " + data.jugador.filename
                        : defaultImage
                    }
                  />
                  <div className=" bg-gray-200 ">
                    <h2 class="text-xl font-semibold text-center mt-3">
                      {data.jugador.apellido + " " + data.jugador.nombre}
                    </h2>
                    <h2 class="text-lg  text-center mt-2">
                      DNI {data.jugador.dni}
                    </h2>
                  </div>
                  <span
                    className={`bg-${
                      data.aprobado === "APTO" ? "green-500" : "red-500"
                    } text-white rounded px-8 py-2 text-xl font-medium text-center`}
                  >
                    {data.aprobado}
                  </span>
                </>
              ) : (
                <h2 class="text-2xl font-semibold text-center my-4">
                  Error al validar jugador
                </h2>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
