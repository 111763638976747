import { useState } from "react";
import axiosClient from "config/axios";
import Alert from "components/Alerts/Alert";
import FotosEstudio from "components/Registro/FotosEstudio";
import Container from "components/Registro/Container";
import setErrorAlert from "utils/setErrorAlert";
import getHTTPHeaders from "utils/getHTTPHeaders";
import Title from "components/Registro/Title";
import SelectJugador from "components/Registro/SelectJugador";
import AproveButtons from "components/Registro/AproveButtons";
import TextAreaInput from "components/Registro/TextAreaInput";
import { useHistory } from "react-router";
import sendFile from "utils/sendRegisterFile";
import DetalleAsistencia from "components/Registro/Detalle/DetalleAsistencia";
import DetalleCardiologia from "components/Registro/Detalle/DetalleCardiologia";
import DetalleNutricion from "components/Registro/Detalle/DetalleNutricion";
import DetalleClinicaMedica from "components/Registro/Detalle/DetalleClinicaMedica";
import DetallePsicologia from "components/Registro/Detalle/DetallePsicologia";
import DetalleOftalmologia from "components/Registro/Detalle/DetalleOftalmologia";
import DetalleOdontologia from "components/Registro/Detalle/DetalleOdontologia";
import DetalleBioquimica from "components/Registro/Detalle/DetalleBioquimica";

export default function FormRegistro() {
  const [selectedJugador, setSelectedJugador] = useState("0");
  const [actualizando, setActualizando] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const history = useHistory();

  const [observacionesGenerales, setObservacionesGenerales] = useState("");
  const [data, setData] = useState({});

  const [alert, setAlert] = useState({
    show: false,
  });

  const actualizarDatos = (aprove) => {
    if (selectedJugador === "0") {
      setErrorAlert(setAlert, "Debe ingresar un JUGADOR");
      return true;
    }
    if (observacionesGenerales === "") {
      setErrorAlert(setAlert, "Debe ingresar una OBSERVACION GENERAL");
      return true;
    }

    setActualizando(true);
    var payload = {
      detalle: JSON.stringify(data),
      aprobado: aprove,
      observaciones: observacionesGenerales,
      jugador_id: selectedJugador,
    };
    axiosClient
      .post("/registros/crear", payload, getHTTPHeaders())
      .then((response) => {
        // Carga los archivos
        if (selectedFile.length > 0) {
          sendFile(response.data.registro_id, selectedFile);
        }
        setActualizando(false);
        history.push("/admin/list-registros");
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setErrorAlert(setAlert, "Hubo un error creando el estudio.");
        setActualizando(false);
      });
  };

  const RouteDetalle = () => {
    switch (localStorage.getItem("role")) {
      case "CARDIOLOGIA":
        return (
          <DetalleCardiologia
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "NUTRICION":
        return (
          <DetalleNutricion
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "CLINICA MEDICA":
        return (
          <DetalleClinicaMedica
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "PSICOLOGIA":
        return (
          <DetallePsicologia
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "ASISTENCIA SOCIAL":
        return (
          <DetalleAsistencia
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "OFTALMOLOGIA":
        return (
          <DetalleOftalmologia
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "ODONTOLOGIA":
        return (
          <DetalleOdontologia
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      case "BIOQUIMICA":
        return (
          <DetalleBioquimica
            detalle={data}
            disabled={false}
            updateValueInput={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <Container>
        <Title
          title={localStorage.getItem("role")}
          description=" Todos los campos son obligatorios. Una vez realizada la carga no se podra editar."
        />
        <hr />
        <SelectJugador
          selectedJugador={selectedJugador}
          setSelectedJugador={setSelectedJugador}
        />
        <hr />
        {RouteDetalle()}
        <hr />
        <TextAreaInput
          title="OBSERVACIONES GENERALES"
          value={observacionesGenerales}
          onChange={(e) => setObservacionesGenerales(e.target.value)}
          name="observaciones"
          placeholder="Ingrese observaciones generales a considerar"
          rows={2}
        />
        <hr />
        <FotosEstudio
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
        />
        <hr />
        <AproveButtons
          actualizarDatos={actualizarDatos}
          habilitarEdicion={true}
          actualizando={actualizando}
        />
      </Container>
    </>
  );
}
