import React from "react";

export default function Pagination(props) {
  return (
    <div className="px-4 mt-4 hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Mostrando{" "}
          <span className="font-medium">
            {" "}
            {(props.pageCount - 1) * props.itemsPerPage + 1}{" "}
          </span>{" "}
          a
          <span className="font-medium">
            {" "}
            {props.pageCount * props.itemsPerPage > props.totalItems
              ? props.totalItems
              : props.pageCount * props.itemsPerPage}{" "}
          </span>
          de un total de
          <span className="font-medium"> {props.totalItems}</span>
        </p>
      </div>
      <div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {props.pageCount > 1 ? (
            <>
              <button
                onClick={() => props.setPageCount(props.pageCount - 1)}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </>
          ) : null}
          {[...Array(props.totalPages)].map((x, i) => (
            <button
              aria-current="page"
              key={i}
              onClick={() => props.setPageCount(i + 1)}
              className={
                i + 1 === props.pageCount
                  ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              }
            >
              {" "}
              {i + 1}{" "}
            </button>
          ))}

          {props.pageCount < props.totalPages ? (
            <>
              <button
                onClick={() => props.setPageCount(props.pageCount + 1)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </>
          ) : null}
        </nav>
      </div>
    </div>
  );
}
