import TextAreaInput from "components/Registro/TextAreaInput";

export default function DetalleBioquimica(props) {
  return (
    <div>
      <TextAreaInput
        title="HEMOGRAMA"
        value={props.detalle.hemograma ?? ""}
        name="hemograma"
        placeholder="Ingrese observaciones de hemograma"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="ERS"
        value={props.detalle.ers ?? ""}
        name="ers"
        placeholder="Ingrese observaciones de ERS"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="GLUCEMIA"
        value={props.detalle.glucemia ?? ""}
        name="glucemia"
        placeholder="Ingrese observaciones de glucemia"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="UREMIA"
        value={props.detalle.uremia ?? ""}
        name="uremia"
        placeholder="Ingrese observaciones de uremia"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="CREATININEMIA"
        value={props.detalle.creatininemia ?? ""}
        name="creatininemia"
        placeholder="Ingrese observaciones de creatininemia"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="URICEMIA"
        value={props.detalle.uricemia ?? ""}
        name="uricemia"
        placeholder="Ingrese observaciones de uricemia"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="PERFIL LIPÍDICO"
        value={props.detalle.lipidico ?? ""}
        name="lipidico"
        placeholder="Ingrese observaciones del perfil lipídico"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="COLESTEROL TOTAL"
        value={props.detalle.colesterol ?? ""}
        name="colesterol"
        placeholder="Ingrese observaciones del colesterol total"
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
      <TextAreaInput
        title="HEPATOGRAMA"
        value={props.detalle.hepatograma ?? ""}
        name="hepatograma"
        placeholder="Ingrese observaciones del hepatograma "
        onChange={props.updateValueInput}
        disabled={props.disabled}
        rows={1}
      />
    </div>
  );
}
