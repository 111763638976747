import React from "react";

export default function Title(props) {
  return (
    <>
      <div>
        <h2 className=" mb-2 text-2xl font-semibold leading-tight">
          {props.title}
        </h2>
        {props.description ? (
          <p className="mb-3 text-gray-600">{props.description}</p>
        ) : null}
      </div>
    </>
  );
}
