import TextAreaInput from "components/Registro/TextAreaInput";

export default function DetalleCardiologia(props) {
  return (
    <div>
      <TextAreaInput
        title="AUSCULTACION"
        value={props.detalle.auscultacion ?? ""}
        name="auscultacion"
        placeholder="Ingrese observaciones de la auscultacion"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="ARRITMIA"
        value={props.detalle.arritmia ?? ""}
        name="arritmia"
        placeholder="Ingrese observaciones de la arritmia"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="SOPLOS"
        value={props.detalle.soplos ?? ""}
        name="soplos"
        placeholder="Ingrese observaciones de los soplos"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
      <TextAreaInput
        title="TENSION ARTERIAL"
        value={props.detalle.tension ?? ""}
        name="tension"
        placeholder="Ingrese observaciones de la tension arterial"
        onChange={props.updateValueInput}
        disabled={props.disabled}
      />
    </div>
  );
}
