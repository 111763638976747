import { useState, useContext, useEffect } from "react";
import axiosClient from "../../config/axios";
import Switch from "react-switch";
import Alert from "components/Alerts/Alert";
import AdminContext from "context/admin/adminContext";
import ConfirmButton from "components/Buttons/ConfirmButton";
import setErrorAlert from "utils/setErrorAlert";
import getHTTPHeaders from "utils/getHTTPHeaders";
import setSuccessAlert from "utils/setSuccessAlert";

function FormEspecialista() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [loadingDatos, setLoadingDatos] = useState(false);
  const [estado, setEstado] = useState(true);
  const [actualizando, setActualizando] = useState(false);
  const [especialistaDatos, setEspecialistaDatos] = useState({
    username: "",
    password: "",
    confirm_password: "",
    nombre: "",
    mail: "",
    apellido: "",
    matricula: "",
    especialidad: "0",
    estado: true,
  });
  const [alert, setAlert] = useState({
    show: false,
  });

  const updateSelect = (value, target) => {
    var newDatos = { ...especialistaDatos };
    newDatos[target] = value;
    setEspecialistaDatos(newDatos);
  };

  const updateValue = (e) => {
    var newDatos = { ...especialistaDatos };
    newDatos[e.target.name] = e.target.value;
    setEspecialistaDatos(newDatos);
  };

  const onSwitchChange = () => {
    var newDatos = { ...especialistaDatos };
    setEstado(!estado);
    newDatos["estado"] = !estado;
    setEspecialistaDatos(newDatos);
  };

  const actualizarDatos = () => {
    if (especialistaDatos.nombre === "") {
      setErrorAlert(setAlert, "Debe ingresar un NOMBRE");
      return true;
    }

    if (especialistaDatos.apellido === "") {
      setErrorAlert(setAlert, "Debe ingresar un APELLIDO");
      return true;
    }

    if (especialistaDatos.matricula === "") {
      setErrorAlert(setAlert, "Debe ingresar una MATRICULA");
      return true;
    }

    if (especialistaDatos.especialidad === "0") {
      setErrorAlert(setAlert, "Debe ingresar una ESPECIALIDAD");
      return true;
    }

    if (especialistaDatos.username === "") {
      setErrorAlert(setAlert, "Debe ingresar un NOMBRE DE USUARIO");
      return true;
    }

    if (especialistaDatos.password !== especialistaDatos.confirm_password) {
      setErrorAlert(setAlert, "Las contraseñas no coinciden");
      return false;
    }

    setActualizando(true);
    // Crea el especialista
    var payload = {
      nombre: especialistaDatos.nombre,
      apellido: especialistaDatos.apellido,
      matricula: especialistaDatos.matricula,
      especialidad: especialistaDatos.especialidad,
      username: especialistaDatos.username,
      password: especialistaDatos.password,
      mail: especialistaDatos.mail,
      activo: especialistaDatos.estado,
    };
    axiosClient
      .post("especialistas/crear", payload, getHTTPHeaders())
      .then((response) => {
        if (response.data.code === 201) {
          setSuccessAlert(setAlert, "Especialista creado exitosamente");
          setEspecialistaDatos({
            username: "",
            password: "",
            confirm_password: "",
            nombre: "",
            apellido: "",
            matricula: "",
            mail: "",
            especialidad: "0",
            estado: true,
          });
        } else {
          setErrorAlert(
            setAlert,
            "Hubo un error creando el especialista, intentá más tarde"
          );
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }

        setErrorAlert(
          setAlert,
          "Hubo un error creando el especialista, intentá más tarde"
        );
        setActualizando(false);
      });
  };

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-2">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Nuevo Especialista
        </h1>
        <p className="mb-6 text-gray-600">
          Acá podés crear un nuevo especialista para su posterior seguimiento.
        </p>
        <div className="lg:grid lg:grid-cols-5 gap-4 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE
            </label>
            <input
              name="nombre"
              value={especialistaDatos.nombre}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              APELLIDO
            </label>
            <input
              name="apellido"
              value={especialistaDatos.apellido}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Apellido"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              MATRICULA
            </label>
            <input
              name="matricula"
              value={especialistaDatos.matricula}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Matricula"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ESPECIALIDAD
            </label>
            <select
              name="especialidad"
              value={especialistaDatos.especialidad}
              onChange={(e) => updateSelect(e.target.value, "especialidad")}
              className=" rounded px-2  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none  "
            >
              <option value="0">Seleccione una especialidad</option>
              <option value="NUTRICION">NUTRICION</option>
              <option value="CLINICA MEDICA">CLINICA MEDICA</option>
              <option value="CARDIOLOGIA">CARDIOLOGIA</option>
              <option value="PSICOLOGIA">PSICOLOGIA</option>
              <option value="ASISTENCIA SOCIAL">ASISTENCIA SOCIAL</option>
              <option value="OFTALMOLOGIA">OFTALMOLOGIA</option>
              <option value="ODONTOLOGIA">ODONTOLOGIA</option>
              <option value="BIOQUIMICA">BIOQUIMICA</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ACTIVO
            </label>
            <Switch
              name="estado"
              onChange={onSwitchChange}
              checked={especialistaDatos.estado}
            />
          </div>
        </div>
        <hr />
        <div className="lg:grid lg:grid-cols-4 gap-6 space-y-4 lg:space-y-0 mt-8 mb-6">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE DE USUARIO
            </label>
            <input
              name="username"
              value={especialistaDatos.username}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre de Usuario"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              MAIL
            </label>
            <input
              name="mail"
              value={especialistaDatos.mail}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Mail"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              CONTRASEÑA
            </label>
            <input
              name="password"
              type="password"
              value={especialistaDatos.password}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none border-0"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Contraseña"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              CONFIRMAR CONTRASEÑA
            </label>
            <input
              name="confirm_password"
              type="password"
              value={especialistaDatos.confirm_password}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none border-0"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={
                loadingDatos ? "Cargando..." : "Confirmar Contraseña"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>

        <ConfirmButton
          label={"Crear especialista"}
          actualizarDatos={actualizarDatos}
          setHabilitarEdicion={setHabilitarEdicion}
          habilitarEdicion={habilitarEdicion}
          actualizando={actualizando}
        />
      </div>
    </>
  );
}

export default FormEspecialista;
