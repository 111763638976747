/*eslint-disable*/
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AdminContext from "context/admin/adminContext";

import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const adminContext = useContext(AdminContext);
  const { log_out } = adminContext;
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-48 z-10 py-2 px-5">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <h1 className=" text-lg uppercase font-bold block pt-2 ">
            DNI DEPORTIVO
          </h1>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="w-6/12  ">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>

            {/* Divider */}
            <hr className="mb-2 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block py-2 no-underline">
              Menú Principal
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center hover:bg-gray-50">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Bienvenida
                </Link>
              </li>

              {localStorage.getItem("role") !== "True" ? (
                <li className="items-center hover:bg-gray-50">
                  <Link
                    className={
                      "text-xs uppercase py-2 font-bold block  " +
                      (window.location.href.indexOf("/admin/add-registro") !==
                      -1
                        ? "text-lightBlue-500 hover:text-lightBlue-600"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/admin/add-registro"
                  >
                    <i
                      className={
                        "fas fa-plus mr-3 text-sm " +
                        (window.location.href.indexOf("/admin/add-registro") !==
                        -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Cargar estudio
                  </Link>
                </li>
              ) : null}

              <li className="items-center hover:bg-gray-50">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/list-registros") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-registros"
                >
                  <i
                    className={
                      "fas fa-newspaper mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/list-registros") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Estudios
                </Link>
              </li>
            </ul>

            {localStorage.getItem("role") == "True" ? (
              <>
                {/* Divider */}
                <hr className="my-2 md:min-w-full" />

                <div>
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block py-2 no-underline">
                    Administracion
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none ">
                    <li className="items-center hover:bg-gray-50">
                      <Link
                        className={
                          "text-xs uppercase py-2 font-bold block " +
                          (window.location.href.indexOf(
                            "/admin/list-especialistas"
                          ) !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/list-especialistas"
                      >
                        <i
                          className={
                            "fas fa-solid fa-briefcase mr-2 text-sm " +
                            (window.location.href.indexOf(
                              "/admin/list-especialistas"
                            ) !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Especialistas
                      </Link>
                    </li>
                    <li className="items-center hover:bg-gray-50">
                      <Link
                        className={
                          "text-xs uppercase py-2 font-bold block " +
                          (window.location.href.indexOf(
                            "/admin/list-jugadores"
                          ) !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/list-jugadores"
                      >
                        <i
                          className={
                            "fas fa-users fa-bus mr-2 text-sm " +
                            (window.location.href.indexOf(
                              "/admin/list-jugadores"
                            ) !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Jugadores
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            ) : null}

            {/* Divider */}
            <hr className="my-2 md:min-w-full" />

            <div>
              <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block py-2 no-underline">
                Usuario
              </h6>
              <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-2">
                <li className="items-center hover:bg-gray-50">
                  <Link
                    className={
                      "text-xs uppercase py-2 font-bold block " +
                      (window.location.href.indexOf("/admin/edit-profile") !==
                      -1
                        ? "text-lightBlue-500 hover:text-lightBlue-600"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/admin/edit-profile"
                  >
                    <i
                      className={
                        "fas fa-user mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/edit-profile") !==
                        -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    MI PERFIL
                  </Link>
                </li>
                <li className="items-center hover:bg-gray-50">
                  <Link
                    className={
                      "text-xs uppercase py-2 font-bold block text-blueGray-700 hover:text-blueGray-500"
                    }
                    onClick={log_out}
                    to="/"
                  >
                    <i
                      className={
                        "fas fa-arrow-right mr-2 text-sm text-blueGray-300"
                      }
                    ></i>{" "}
                    CERRAR SESION
                  </Link>
                </li>
              </ul>
            </div>
            {/* Divider */}
            <hr className=" md:min-w-full" />
          </div>
        </div>
      </nav>
    </>
  );
}
