import React from "react";
import { useEffect, useState } from "react";
import axiosClient from "config/axios";

import getHTTPHeaders from "utils/getHTTPHeaders";

export default function SelectJugador(props) {
  const [jugadores, setJugadores] = useState([]);
  useEffect(() => {
    axiosClient("jugadores", getHTTPHeaders()).then((res) => {
      setJugadores(res.data.jugadores.items);
    });
  }, []);

  function calcularEdad(fechaNacimientoStr) {
    const [anio, mes, dia] = fechaNacimientoStr.split("-").map(Number);
    const hoy = new Date();
    let edad = hoy.getFullYear() - anio;
    return hoy.getMonth() + 1 < mes ||
      (hoy.getMonth() + 1 === mes && hoy.getDate() < dia)
      ? edad - 1
      : edad;
  }

  return (
    <>
      <div className="flex flex-col my-3">
        <label className="text-gray-500 text-xs font-medium mb-1">
          JUGADOR
        </label>
        <select
          onChange={(e) => props.setSelectedJugador(e.target.value)}
          value={props.selectedJugador}
          className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
        >
          <option selected value="0">
            {props.label ?? "Seleccione un jugador"}
          </option>
          {jugadores.map((jugador) => (
            <option key={jugador.id} value={jugador.id}>
              {jugador.apellido +
                " " +
                jugador.nombre +
                " - DNI " +
                jugador.dni +
                " - " +
                " EDAD " +
                calcularEdad(jugador.fecha_nacimiento) +
                " - " +
                jugador.club}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
