import React from "react";
import PropTypes from "prop-types";

export default function CardTable({ color }) {
  return (
    <div>
      <div
        className={
          "relative flex flex-col min-w-0 mt-4 break-words w-full mb-2 shadow-lg rounded border-gray-900 border-2 my-4 py-8 " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-start">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-center">
              <h3
                className={
                  "font-semibold text-2xl text-center" +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                ¡Hola, {localStorage.getItem("username")}! Bienvenido a DNI
                DEPORTIVO
              </h3>
              <div className="flex justify-center mt-4 mb-2">
                <p className="font-medium text-lg text-center ">
                  {" "}
                  En esta seccion se ofrecen consideraciones a tener en cuenta
                  para el uso del sistema.
                </p>
              </div>
              <hr />
              <div className="flex mt-4">
                <p className="font-medium text-md text-center">
                  {" "}
                  - Cada especialista puede cargar un tipo de estudio especifico
                  de acuerdo a su especialidad.
                </p>
              </div>

              <div className="flex mt-4">
                <p className="font-medium text-md text-center">
                  {" "}
                  - Un jugador puede tener asociados varios estudios realizados
                  por diferentes especialistas.
                </p>
              </div>
              <div className="flex mt-4">
                <p className="font-medium text-md text-center">
                  {" "}
                  - Un estudio se cargara como APTO/NO APTO y con la informacion
                  correspondiente segun la especialidad.
                </p>
              </div>
              <div className="flex mt-4">
                <p className="font-medium text-md text-center">
                  {" "}
                  - Un estudio cargado a un jugador no se podra modificar una
                  vez registrado.
                </p>
              </div>
              <div className="flex mt-4">
                <p className="font-medium text-md text-center">
                  {" "}
                  - Un especialista solo vera los estudios cargados por si
                  mismo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
