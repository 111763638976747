import React from "react";
import InputItem from "components/Registro/InputItem";

export default function TextAreaInput(props) {
  return (
    <>
      <InputItem title={props.title}>
        <textarea
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          disabled={props.disabled ?? false}
          placeholder={props.disabled ? "-" : props.placeholder}
          className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
          rows={props.rowa ?? 2}
        ></textarea>
      </InputItem>
    </>
  );
}
