import React from "react";

export default function InputItem(props) {
  return (
    <>
      <div className="flex flex-col my-2">
        <label className="text-gray-500 text-xs font-medium mb-1">
          {props.title}
        </label>
        {props.children}
      </div>
    </>
  );
}
