import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import Dashboard from "views/admin/Dashboard.js";
import EditarUsuario from "views/admin/EditarUsuario.js";
import TableRegistro from "views/admin/TableRegistro.js";

import TableJugadores from "views/admin/TableJugadores";
import EditarJugador from "views/admin/EditarJugador";
import FormJugador from "views/admin/FormJugador";
import TableEspecialistas from "views/admin/TableEspecialistas";
import EditarEspecialista from "views/admin/EditarEspecialista";
import FormEspecialista from "views/admin/FormEspecialista";
import VerEstudio from "views/admin/VerEstudio";
import FormRegistro from "views/admin/FormRegistro";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-48 bg-blueGray-100">
        <AdminNavbar />
        <div className="p-2 md:p-3 mx-auto w-full h-screen flex flex-col gap-4">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/edit-profile" exact component={EditarUsuario} />

            <Route
              path="/admin/ver-registro/:id_registro"
              exact
              component={VerEstudio}
            />
            <Route
              path="/admin/list-registros"
              exact
              component={TableRegistro}
            />
            <Route path="/admin/add-registro" exact component={FormRegistro} />

            <Route
              path="/admin/list-jugadores"
              exact
              component={TableJugadores}
            />
            <Route path="/admin/add-jugador" exact component={FormJugador} />
            <Route
              path="/admin/edit-jugador/:id_jugador"
              exact
              component={EditarJugador}
            />

            <Route
              path="/admin/list-especialistas"
              exact
              component={TableEspecialistas}
            />
            <Route
              path="/admin/add-especialista"
              exact
              component={FormEspecialista}
            />
            <Route
              path="/admin/edit-especialista/:id_especialista"
              exact
              component={EditarEspecialista}
            />

            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
