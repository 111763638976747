import TextAreaInput from "components/Registro/TextAreaInput";

export default function DetalleNutricion(props) {
  const campos = [
    {
      title: "PESO",
      value: props.detalle.peso,
      name: "peso",
      placeholder: "Ingrese observaciones del peso",
    },
    {
      title: "TALLA",
      value: props.detalle.talla,
      name: "talla",
      placeholder: "Ingrese observaciones de la talla",
    },
    {
      title: "Envergadura de brazos en arqueros",
      value: props.detalle.envergadura ?? "",
      name: "envergadura",
      placeholder: "Ingrese la envergadura de brazos",
    },
    {
      title: "Masa adiposa (kg)",
      value: props.detalle.masaAdiposa ?? "",
      name: "masaAdiposa",
      placeholder: "Ingrese la masa adiposa",
    },
    {
      title: "Masa muscular (kg)",
      value: props.detalle.masaMuscular ?? "",
      name: "masaMuscular",
      placeholder: "Ingrese la masa muscular",
    },
    {
      title: "Masa ósea (kg)",
      value: props.detalle.masaOsea ?? "",
      name: "masaOsea",
      placeholder: "Ingrese la masa ósea",
    },
    {
      title: "Sumatoria de 6 pliegues",
      value: props.detalle.sumatoriaPliegues ?? "",
      name: "sumatoriaPliegues",
      placeholder: "Ingrese la sumatoria de 6 pliegues",
    },
    {
      title: "Índice de músculo óseo (IMO)",
      value: props.detalle.imo ?? "",
      name: "imo",
      placeholder: "Ingrese el índice de músculo óseo",
    },
    {
      title: "Objetivo",
      value: props.detalle.objetivo ?? "",
      name: "objetivo",
      placeholder: "Ingrese el objetivo",
    },
  ];

  return (
    <div>
      {campos.map((campo) => (
        <TextAreaInput
          key={campo.name}
          title={campo.title}
          value={campo.value}
          name={campo.name}
          placeholder={campo.placeholder}
          onChange={props.updateValueInput}
          disabled={props.disabled}
        />
      ))}
    </div>
  );
}
